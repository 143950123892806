.reactflow-wrapper
{
  height:100%;width:100%
}

.reactflow-wrapper .react-flow__handle {
  width: 8px;
  height: 8px;
}
.reactflow-wrapper .react-flow__handle:hover {    
  width: 10px;
  height: 10px;
}

.dndflow {
    flex-direction: column;
    display: flex;
    height: 100%;
    width:100%;
  }
  
  .dndflow aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
  }
  
  .dndflow aside > * {
    margin-bottom: 10px;
    cursor: grab;
  }
  
  .dndflow aside .description {
    margin-bottom: 10px;
  }
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  .react-flow__node-input {
    background: #fff;
    border-color: gold;
}
.react-flow__node-output {
  background: #fff;
  border-color: blue;
}

  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 280px;
    }
  }