.parentFlexRight {
	margin-right: 0;
}
.drawerContainer {
	flex-direction: row;
	flex-wrap: wrap;    
} 
.drawerContainer header, 
.drawerContainer footer {
	width: 100%;
}

.add-query-sidebar {
	order: 1;
	flex: 1;
}
.rule-details-sidebar {
	flex: 1;
	order: 2;
}
.drawerContainer footer {
	order: 4;
}
.fullText { width: 100%; }

.rule-details-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	padding-bottom: 1.5rem;
}
.rule-details-header .header-btns {
	text-align: right;
}

.query-container__builder{
	min-height: 100%;
}

