.adjusted-menu-position-rehab .InfoCard--header {
  padding-right: 0.3rem;
}

.adjusted-menu-position-rehab .ContentCard--body {
  margin-right: -7.5rem;
  width: 100%;
}

.header-container {
  display: flex;
  width: 100%;
  align-items: center;
}